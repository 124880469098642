<template>

    <div>
        <div class="front-top-content text-center">
            <h3>Reimposta la tua password</h3>
            <p></p>
        </div>

        <div class="col d-sm-flex justify-content-center">
            <div class="card card-login">
                <div class="card-body">
                    <form v-on:submit="resetPassword" class="">
                        <div class="form-group">
                            <input
                                type="password"
                                class="form-control"
                                id="newpasswd"
                                ref="newpasswd"
                                required
                                name="newpasswd"
                                v-model="newpasswd"
                                placeholder="Nuova password"
                            />
                        </div>
                        <div class="form-group">
                            <input
                                type="password"
                                class="form-control"
                                id="newpasswdrepeat"
                                required
                                name="newpasswdrepeat"
                                v-model="newpasswdrepeat"
                                placeholder="Ripeti la nuova password"
                            />
                        </div>
                        <div class="form-group">
                            <button class="btn btn-success btn-block" type="submit">Reimposta Password</button>
                        </div>
                    </form>
                    <p class="text-danger text-center" v-if="errorMsg">
                        {{ errorMsg }}<br>
                        <router-link class="btn btn-link" to="/forgot-password" v-if="goBack"><b-icon icon="arrow-left"/> Riprova</router-link>
                    </p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AuthDataService from "./AuthDataService";
import {required} from 'vuelidate/lib/validators'
import {HomepageByRole} from "@/helpers/HomepageByRole";

export default {
    name: "reset-password",
    data() {
        return {
            newpasswd: "",
            newpasswdrepeat: "",
            errorMsg: null,
            token: null,
            goBack: false
        };
    },
    validations: {
        newpasswd: {
            required
        },
        newpasswdrepeat: {
            required
        }
    },
    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.item[name];
            return $dirty ? !$error : null;
        },
        resetPassword(e) {
            e.preventDefault();
            if (this.$v.$invalid) {
                this.submitted = false;
                this.errorMsg = 'I due campi sono obbligatori';
            } else {
                if (this.newpasswd != this.newpasswdrepeat) {
                    this.submitted = false;
                    this.errorMsg = 'Le due password devono coincidere';
                } else {
                    let data = {
                        newpasswd: this.newpasswd,
                        token: this.token,
                    }
                    AuthDataService.resetPassword(data)
                        .then(response => {
                            this.$store.state.loading = false;
                            // redirect to page response
                            this.$router.push('/reset-password-ok');
                        })
                        .catch(errors => {
                            this.$store.state.loading = false;
                            if (errors.response.status == 400) {
                                this.errorMsg = "Token invalido, richiesta scaduta.";
                                this.goBack = true;
                            } else {
                                this.errorMsg = errors
                            }
                        });
                }
            }
        },
        focusEmail() {
            this.$refs.email.focus();
        },
    },
    mounted() {
        // if logged then redirect
        if (this.$store.state.isLoggedIn) {
            const uri = HomepageByRole(this.$store.state.user.roles[0]);
            this.$router.push(uri).catch(e => {});
        } else {
            this.token = this.$route.query.token;
            // check if toke exist
            AuthDataService.isValidNewPassToken(this.token)
                .catch(errors => {
                    //this.errorMsg = "Il token è scaduto o inesistente.";
                    //this.goBack = true;
                    // if token invalid redirect to login
                    this.$router.push('/login');
                });
        }
    }
}
</script>
